import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {BggSyncConfig, UserBggDataResponse} from "../../model/responses";
import {environment} from "../../../environments/environment";

@Injectable()
export class UserBggService {

  constructor(
    private http: HttpClient
  ) {
  }

  getBggData(): Observable<UserBggDataResponse> {
    return this.http.get<any>(`${environment.apiUrl}/users/me/bgg`);
  }

  updateBggConfig(bggUsername: string, config: BggSyncConfig): Observable<any> {
    return this.http.put<any>(`${environment.apiUrl}/users/me/bgg/config`, {bggUsername: bggUsername, config: config});
  }

  syncBggData(): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/users/me/bgg/sync`, {});
  }
}
